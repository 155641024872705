<template>
  <div class="form-control" :class="{'error': error}">
    <div v-if="label" class="form-control__label">{{ label }}</div>
    <div class="form-control__content">
      <slot />
    </div>
    <div v-if="error" class="form-control__error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'FormControl',
  props: {
    label: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    }
  }
}
</script>
