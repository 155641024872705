export const mapDataToSubmitData = (data) => ({
  fio: data?.fio ? data.fio : '',
  age: data?.age ? data.age : '',
  email: data?.email ? data.email : '',
  phone: data?.phone ? data.phone : '',
  city: data?.city ? data.city : '',
  work_place: data?.workPlace ? data.workPlace : '',
  position: data?.position ? data.position : '',
  experience_working_with: data?.experienceWorkingWith,
  request: data?.request ? data.request : [],
  preferred_work_format: data?.preferredWorkFormat ? data.preferredWorkFormat : '',
  preferred_consultation_time: data?.preferredConsultationTime ? data.preferredConsultationTime : [],
  where_found: data?.whereFound ? data.whereFound : []
});
