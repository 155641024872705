import './utils/styles';
import './utils/sprite';

import { createApp } from 'vue';
import Maska from 'maska';

import Business from './components/business/index.vue';
import Psych from './components/psych/index.vue';
import Corparate from './components/corparate/index.vue';
import FormAuth from './components/formAuth/index.vue';
import FormChangePassword from './components/formChangePassword/index.vue';

const createMyApp = (options) => {
  const app = createApp(options);
  app.use(Maska);

  return app;
};

createMyApp(FormAuth).mount('#authForm');
createMyApp(FormChangePassword).mount('#changePasswordForm');
createMyApp(Business).mount('#business');
createMyApp(Psych).mount('#psych');
createMyApp(Corparate).mount('#corparate');
