<template>
  <span class="svg-icon">
    <svg class="simple-icon" :width="width" :height="height">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="`#${icon}`"/>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'icon',
  props: {
    icon: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    }
  }
};
</script>
