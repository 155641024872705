<template>
  <div class="auth-form__wrap">
    <form class="auth-form">
      <div class="auth-form__title">Авторизоваться</div>

      <div class="auth-form__elements">
        <ElementForm
            :data="form?.email"
            :activeValidate="activeValidate"
            @change="handleChange($event, form?.email?.name)"
            @update-validate="setValidate($event, form?.email?.name)"
        />
        <ElementForm
            :data="form?.password"
            :activeValidate="activeValidate"
            @change="handleChange($event, form?.password?.name)"
            @update-validate="setValidate($event, form?.password?.name)"
        />
        <div
            class="auth-form__toogle-form"
            @click="updateForgotPassword"
        >
          Восстановить пароль
        </div>
        <ElementForm
            :data="form?.remainInSystem"
            :activeValidate="activeValidate"
            @change="handleChange($event, form?.remainInSystem?.name)"
            @update-validate="setValidate($event, form?.remainInSystem?.name)"
        />
      </div>

      <div class="auth-form__btns">
        <ButtonUI
            title="Войти"
            type="submit"
            @click="submitForm"
        />
      </div>

      <div v-if="errors?.length" class="auth-form__errors">
        <div v-for="item in errors" :key="item">{{ item }}</div>
      </div>
    </form>
  </div>
</template>

<script>
import { api } from '../../../api';
import ElementForm from '../ui/Form/ElementForm'
import ButtonUI from '../ui/ButtonUI'
import { scrollToFirstElementWithError } from '../ui/Form/utils'

export default {
  name: 'Auth',
  components: {
    ElementForm,
    ButtonUI
  },
  emits: ['update-active-form', 'show-loader'],
  data () {
    return {
      activeValidate: false,
      model: {},
      validate: {},
      form: {
        email: {
          type: 'input',
          name: 'email',
          attributes: {
            label: 'Email',
            placeholder: 'Введите e-mail'
          },
          validators: {
            required: true,
            email: true
          }
        },
        password: {
          type: 'input',
          name: 'password',
          attributes: {
            label: 'Пароль',
            placeholder: 'Введите пароль',
            type: 'password'
          },
          validators: {
            required: true
          }
        },
        remainInSystem: {
          type: 'checkbox',
          name: 'remainInSystem',
          attributes: {
            text: 'Оставаться в системе'
          },
          validators: {}
        }
      },
      errors: []
    }
  },
  methods: {
    handleChange (value, name) {
      this.model[name] = value
      this.errors = []
    },
    setValidate (value, name) {
      if (value) {
        this.validate[name] = value
      } else if (this.validate[name]) {
        delete this.validate[name]
      }
    },
    submitForm ($event) {
      if ($event) {
        this.activeValidate = true

        if (Object.keys(this.validate)?.length === 0 && this.errors?.length === 0) {
          // Тогда отправляем форму
          this.$emit('show-loader', true)

          api.sendAuthForm({
            email: this.model?.email,
            password: this.model?.password,
            user_remember: this.model?.remainInSystem
          })
              .then((result) => {
                if (result?.data?.data?.success) {
                  // При успешной отправке формы - переходим в раздел personal
                  window.location.href = '/personal'
                } else if (result?.data?.data?.errors?.length) {
                  // Если ошибка - заполняем её текстом с бэка
                  this.errors = result?.data?.data?.errors
                }

                this.$emit('show-loader', false)
              })
              .catch(() => {
                this.$emit('show-loader', false)
              })
        } else {
          scrollToFirstElementWithError(`[data-form-control="${Object.keys(this.validate)?.[0]}"]`)
        }
      }
    },
    updateForgotPassword ($event) {
      if ($event) {
        this.$emit('update-active-form', 'forgot-password')
      }
    }
  }
};
</script>
