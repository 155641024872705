import {
  REGEX_INTEGER,
  REGEX_EMAIL,
  REGEX_URL,
  TYPE_VALIDATOR,
  msgValidate,
  REGEX_NO_RUS_SYMBOL
} from './utils';

const getMessage = (name, value, params) => {
  let errMessage = '';

  switch (name) {
    case TYPE_VALIDATOR.REQUIRED:
      if (!value || (value && Array.isArray(value) && !value.length)) {
        errMessage = msgValidate(TYPE_VALIDATOR.REQUIRED);
      }
      break;

    case TYPE_VALIDATOR.MIN_LENGTH:
      if (params?.quantity && value?.length && value.length < params.quantity) {
        errMessage = msgValidate(
          TYPE_VALIDATOR.MIN_LENGTH,
          params.quantity
        );
      }
      break;

    case TYPE_VALIDATOR.MAX_LENGTH:
      if (params?.quantity && value?.length && value.length > params.quantity) {
        errMessage = msgValidate(
          TYPE_VALIDATOR.MAX_LENGTH,
          params.quantity
        );
      }
      break;

    case TYPE_VALIDATOR.EMAIL:
      if (!REGEX_EMAIL.test(value) && value) {
        errMessage = msgValidate(TYPE_VALIDATOR.EMAIL);
      }
      break;

    case TYPE_VALIDATOR.INTEGER:
      if (!REGEX_INTEGER.test(value) && value) {
        errMessage = msgValidate(TYPE_VALIDATOR.INTEGER);
      }
      break;

    case TYPE_VALIDATOR.URL:
      if (!REGEX_URL.test(value) && value) {
        errMessage = msgValidate(TYPE_VALIDATOR.URL);
      }
      break;

    case TYPE_VALIDATOR.NO_RUS_SYMBOL:
      if (!REGEX_NO_RUS_SYMBOL.test(value) && value) {
        errMessage = msgValidate(TYPE_VALIDATOR.NO_RUS_SYMBOL);
      }
      break;

    default:
      break;
  }

  return errMessage;
};

export const getValidField = (value, validators) => {
  let errMessage = '';
  if (!validators) return '';

  Object.keys(validators)?.forEach((key) => {
    if (!errMessage) {
      const validator = validators[key];
      const name = validator?.name ? validator.name : key;
      // Если у нас валидатор по name из массива, а не из объекта по ключу - то берём params
      const params = validator?.name ? validator.params : validator;

      errMessage = getMessage(name, value, params);
    }
  });

  return errMessage;
};
