<template>
  <div class="auth-form__wrap">
    <form v-if="!isSentSuccess" class="auth-form">
      <div class="auth-form__subtitle">Выслать контрольную строку</div>
      <div class="auth-form__text">
        Если вы&nbsp;забыли пароль, введите логин или <span class="nobr">E-Mail</span>.
        Контрольная строка для смены пароля, а&nbsp;также ваши регистрационные данные,
        будут высланы вам по&nbsp;<span class="nobr">E-Mail</span>.
      </div>

      <div class="auth-form__elements">
        <ElementForm
            :data="form?.email"
            :activeValidate="activeValidate"
            @change="handleChange($event, form?.email?.name)"
            @update-validate="setValidate($event, form?.email?.name)"
        />
        <div
          class="auth-form__toogle-form"
          @click="updateForgotPassword"
        >
          Вернуться к&nbsp;авторизации
        </div>
      </div>

      <div class="auth-form__btns">
        <ButtonUI
          title="Отправить"
          type="submit"
          @click="submitForm"
        />
      </div>

      <div v-if="errors?.length" class="auth-form__errors">
        <div v-for="item in errors" :key="item">{{ item }}</div>
      </div>
    </form>
    <div v-else class="info-auth-form">
      Контрольная строка, а&nbsp;также ваши регистрационные данные были высланы на&nbsp;email.
      Пожалуйста, дождитесь письма, так как контрольная строка изменяется при каждом запросе.
    </div>
  </div>
</template>

<script>
import { api } from '../../../api';
import ElementForm from '../ui/Form/ElementForm'
import ButtonUI from '../ui/ButtonUI'
import { scrollToFirstElementWithError } from '../ui/Form/utils'

export default {
  name: 'ForgotPassword',
  components: {
    ElementForm,
    ButtonUI
  },
  emits: ['update-active-form', 'show-loader'],
  data () {
    return {
      activeValidate: false,
      model: {},
      validate: {},
      form: {
        email: {
          type: 'input',
          name: 'email',
          attributes: {
            label: 'Email',
            placeholder: 'Введите e-mail'
          },
          validators: {
            required: true,
            email: true
          }
        },
        password: {
          type: 'input',
          name: 'password',
          attributes: {
            label: 'Пароль',
            placeholder: 'Введите пароль',
            type: 'password'
          },
          validators: {
            required: true
          }
        },
        remainInSystem: {
          type: 'checkbox',
          name: 'remainInSystem',
          attributes: {
            text: 'Оставаться в системе'
          },
          validators: {}
        }
      },
      isSentSuccess: false,
      errors: []
    }
  },
  methods: {
    handleChange (value, name) {
      this.model[name] = value
      this.errors = []
    },
    setValidate (value, name) {
      if (value) {
        this.validate[name] = value
      } else if (this.validate[name]) {
        delete this.validate[name]
      }
    },
    submitForm ($event) {
      if ($event) {
        this.activeValidate = true

        if (Object.keys(this.validate)?.length === 0 && this.errors?.length === 0) {
          // Тогда отправляем форму
          this.$emit('show-loader', true)

          api.sendForgotPasswordForm({
            email: this.model?.email
          })
              .then((result) => {
                if (result?.data?.data?.success) {
                  // При успешной отправке формы - отображаем сообщение
                  // о дальнейших действиях для восстановления пароля
                  this.isSentSuccess = true
                } else if (result?.data?.data?.errors?.length) {
                  // Если ошибка - заполняем её текстом с бэка
                  this.errors = result?.data?.data?.errors
                }

                this.$emit('show-loader', false)
              })
              .catch(() => {
                this.$emit('show-loader', false)
              })
        } else {
          scrollToFirstElementWithError(`[data-form-control="${Object.keys(this.validate)?.[0]}"]`)
        }
      }
    },
    updateForgotPassword ($event) {
      if ($event) {
        this.$emit('update-active-form', 'auth')
      }
    }
  }
};
</script>
