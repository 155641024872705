import { axiosPublicInstance } from './axiosInstance';

export const api = {
  sendFormAppBusiness: (data) => axiosPublicInstance.post('/api/app/business', data),
  sendFormAppPsych: (data) => axiosPublicInstance.post('/api/app/psych/', data),
  sendFormAppCorparate: (data) => axiosPublicInstance.post('/api/app/corparate/', data),
  sendAuthForm: (data) => axiosPublicInstance.post('/local/ajax/auth.php', data),
  sendForgotPasswordForm: (data) => axiosPublicInstance.post('/local/ajax/forgot_password.php', data),
  sendChangePasswordForm: (data) => axiosPublicInstance.post('/local/ajax/change_password.php', data)
};
