export const mapDataToSubmitData = (data) => ({
  fio: data?.fio ? data.fio : '',
  age: data?.age ? data.age : '',
  email: data?.email ? data.email : '',
  phone: data?.phone ? data.phone : '',
  preferred_work_format: data?.preferredWorkFormat ? data.preferredWorkFormat : '',
  preferred_consultation_time: data?.preferredConsultationTime ? data.preferredConsultationTime : [],
  where_found: data?.whereFound ? data.whereFound : [],
  consult_request: data?.consult_request ? data.consult_request : ''
});
