<template>
  <label
    class="checkbox-ui"
    :class="{
      'disabled': disabled,
      'dark': dark
    }"
  >
    <input
      type="checkbox"
      :value="modelValue"
      :title="label"
      :disabled="disabled"
      :checked="modelValue"
    >
    <span class="check-icon" @click="handleOnChange">
      <span class="check-icon__mark" v-if="modelValue"></span>
    </span>
    <span v-if="label || attr?.text" class="label" @click="handleOnChange" v-html="label ? label : attr?.text" />
  </label>
</template>

<script>
export default {
  name: 'CheckboxUI',
  emits: ['update:modelValue', 'change'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    attr: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    handleOnChange () {
      if (!this.disabled) {
        this.$emit('update:modelValue', !this.modelValue)
        this.$emit('change', !this.modelValue)
      }
    }
  }
}
</script>
