<template>
  <div class="checkbox-group" :class="{'checkbox-group--only-one': isOnlyOne}">
    <template v-if="valueCheckbox">
      <CheckboxUI
        v-for="(item, index) in options"
        :key="item?.value"
        v-model="valueCheckbox[index]"
        :label="item?.label"
        class="checkbox-group__item"
        @change="handleOnChangeCheckbox($event, index)"
      />
    </template>

    <TextareaUI
      v-if="other?.show"
      :label="other?.label"
      :placeholder="other?.placeholder"
      class="checkbox-group__other"
      @change="handleOnChangeTextarea($event)"
    />
  </div>
</template>

<script>
import CheckboxUI from '../ui/CheckboxUI'
import TextareaUI from '../ui/TextareaUI'

export default {
  name: 'CheckboxGroupUI',
  emits: ['change'],
  components: {
    CheckboxUI,
    TextareaUI
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    isOnlyOne: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    other: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      valueCheckbox: null,
      resultCheckbox: null,
      resultTextarea: null
    }
  },
  mounted () {
    this.valueCheckbox = this.options?.map(el => !!el?.active)
  },
  methods: {
    handleOnChangeCheckbox (data, index) {
      if (this.isOnlyOne) {
        this.valueCheckbox = this.options?.map((el, indexOptions) => {
          if (index === indexOptions) {
            this.resultCheckbox = data ? el?.value : null
            return data
          }

          return false
        })
      } else {
        this.resultCheckbox = []
        this.valueCheckbox.forEach((el, index) => {
          if (el) {
            this.resultCheckbox.push(this.options?.[index]?.value)
          }
        })
      }

      if (this.other?.show) {
        const result = Array.isArray(this.resultCheckbox) ? [...this.resultCheckbox] : this.resultCheckbox
        if (this.resultTextarea) result.push(this.resultTextarea)

        this.$emit('change', result)
      } else {
        this.$emit('change', this.resultCheckbox)
      }
    },
    handleOnChangeTextarea (data) {
      this.resultTextarea = data
      if (this.resultCheckbox?.length) {
        const result = data ? [...this.resultCheckbox, data] : [...this.resultCheckbox]
        this.$emit('change', result)
      } else {
        this.$emit('change', data ? [data] : null)
      }
    }
  }
};
</script>
