export const MAX_LENGTH_INPUT = 255;
export const MAX_LENGTH_TEXTAREA = 1000;
export const REGEX_NO_RUS_SYMBOL = /^[^а-яА-ЯёЁ]+$/;
export const REGEX_EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const REGEX_INTEGER = /^([0-9]\d*)$/;
// eslint-disable-next-line
export const REGEX_URL = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
export const REGEX_WEB_LINK = /(https?:\/\/[^\s]+)/g;

export const TYPE_VALIDATOR = Object.freeze({
  REQUIRED: 'required',
  PATTERN: 'pattern',
  MIN_LENGTH: 'minLength',
  MAX_LENGTH: 'maxLength',
  EMAIL: 'email',
  URL: 'url',
  INTEGER: 'integer',
  NO_RUS_SYMBOL: 'noRusSymbol'
});

export const FIELDS_MASKS = Object.freeze({
  PHONE: '+7 (999) 999 99 99'
});

const messageBuilderByType = {
  [TYPE_VALIDATOR.REQUIRED]: () => 'Обязательное поле',
  [TYPE_VALIDATOR.MIN_LENGTH]: ({ count }) => `Минимальное количество символов - ${count}`,
  [TYPE_VALIDATOR.MAX_LENGTH]: ({ count }) => `Максимальное количество символов - ${count}`,
  [TYPE_VALIDATOR.EMAIL]: () => 'Введите корректный email',
  [TYPE_VALIDATOR.INTEGER]: () => 'Введите корректное число',
  [TYPE_VALIDATOR.URL]: () => 'Введите корректный url',
  [TYPE_VALIDATOR.NO_RUS_SYMBOL]: () => 'Не должно содержать русских букв'
};

export const msgValidate = (type, count) => {
  const builder = messageBuilderByType[type];
  if (!builder) {
    return '';
  }
  return builder({ count });
};

export const scrollToFirstElementWithError = (selectorElement) => {
  const element = document.querySelector(selectorElement);

  if (element) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const paramsElement = element.getBoundingClientRect();
    // 10 - отступ в пикселях от края экрана
    window.scroll(0, paramsElement.top + scrollTop - 10);
  }
};
