<template>
  <FormControl
    :label="data?.attributes?.label"
    :class="data?.attributes?.class"
    :error="textError"
    :data-form-control="data?.name"
  >
    <component
      :is="componentsForm?.[data?.type]"
      v-model="value"
      :placeholder="data?.attributes?.placeholder"
      :type="data?.attributes?.type"
      :options="data?.options"
      :isOnlyOne="data?.attributes?.isOnlyOne"
      :other="data?.attributes?.other"
      :attr="data?.attributes"
      @change="handleChange($event)"
    />
  </FormControl>
</template>

<script>
import { getValidField } from  './Validators'
import FormControl from '../FormControl'
import ButtonUI from '../ButtonUI'
import CheckboxGroupUI from '../CheckboxGroupUI'
import CheckboxUI from '../CheckboxUI'
import InputUI from '../InputUI'
import TextareaUI from '../TextareaUI'
import TimeWeekUI from '../TimeWeekUI'

export default {
  name: 'ElementForm',
  emits: ['change', 'update-validate'],
  components: {
    FormControl,
    ButtonUI,
    InputUI,
    CheckboxGroupUI,
    CheckboxUI,
    TextareaUI,
    TimeWeekUI
  },
  props: {
    data: {
      type: Object,
      default: () => null
    },
    activeValidate: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      value: null,
      noValid: null,
      componentsForm: {
        input: 'InputUI',
        'checkbox': 'CheckboxUI',
        'group-checkbox': 'CheckboxGroupUI',
        'time-week': 'TimeWeekUI'
      }
    }
  },
  computed: {
    textError () {
      if (this.activeValidate) {
        if (this.noValid) {
          return this.noValid
        } else if (this.error) {
          return this.error
        }
      }

      return ''
    }
  },
  watch: {
    activeValidate (val) {
      if (val) {
        this.noValid = getValidField(this.value, this.data?.validators)
        this.$emit('update-validate', !!this.noValid)
      }
    }
  },
  mounted () {
    this.noValid = getValidField(this.value, this.data?.validators)
    this.$emit('update-validate', !!this.noValid)
  },
  methods: {
    handleChange (value) {
      this.value = value
      this.$emit('change', value)

      this.noValid = getValidField(value, this.data?.validators)
      this.$emit('update-validate', !!this.noValid)
    }
  }
};
</script>
