import axios from 'axios';

export const MAIN_URL = process.env.VUE_APP_MAIN_URL || 'https://hse.n5.ddev.articul.ru';

export const config = {
  baseURL: MAIN_URL,
  headers: {}
};

export const axiosPublicInstance = axios.create(config);
