<template>
  <Auth
      v-if="isLoadPage && activeForm === 'auth'"
      @show-loader="updateShowLoader"
      @update-active-form="setActiveForm"
  />
  <ForgotPassword
      v-if="isLoadPage && activeForm === 'forgot-password'"
      @show-loader="updateShowLoader"
      @update-active-form="setActiveForm"
  />

  <Loader v-if="showLoader" />
</template>

<script>
import Loader from '../Loader';
import Auth from './Auth'
import ForgotPassword from './ForgotPassword'

export default {
  name: 'FormAuth',
  components: {
    Loader,
    Auth,
    ForgotPassword
  },
  data () {
    return {
      showLoader: false,
      isLoadPage: false,
      activeForm: false
    }
  },
  mounted () {
    const paramsSearch = window.location.search.replace( '?', '');
    if (paramsSearch.indexOf('forgot_password') > -1) {
      this.activeForm = 'forgot-password';
    } else {
      this.activeForm = 'auth';
    }

    this.isLoadPage = true;
  },
  methods: {
    setActiveForm (data) {
      this.activeForm = data

      if (data === 'forgot-password') {
        if (history.pushState) {
          const baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
          const newUrl = baseUrl + '?forgot_password';
          history.pushState(null, null, newUrl);
        }
      } else {
        const baseUrl = window.location.href.split("?")[0];
        history.pushState('name', '', baseUrl);
      }
    },
    updateShowLoader (data) {
      this.showLoader = data
    }
  }
};
</script>
