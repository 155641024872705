<template>
  <div class="time-week" :class="[`count-${intervals?.length}`, {'time-week--reverse': attr?.isReverse}]">
    <template v-if="attr?.isReverse">
      <div class="time-week__header">
        <div
            v-for="day in daysWeek"
            :key="day?.id"
            class="time-week__header-item"
        >{{ day?.value }}</div>
      </div>
      <div
          v-for="interval in intervals"
          :key="interval?.id"
          class="time-week__week"
      >
        <div class="time-week__week-title" v-html="interval?.label ? interval.label : interval?.value"></div>
        <div class="time-week__week-content">
          <div
              v-for="item in daysWeek"
              :key="item?.id"
              class="time-week__item"
          >
            <CheckboxUI
                v-model="model[item?.id][interval?.id]"
                @change="handleOnChangeCheckbox"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="time-week__header">
        <div
            v-for="interval in intervals"
            :key="interval?.value"
            class="time-week__header-item"
            v-html="interval?.label ? interval.label : interval?.value"
        />
      </div>
      <div
          v-for="item in daysWeek"
          :key="item?.id"
          class="time-week__week"
      >
        <div class="time-week__week-title">{{ item?.value }}</div>
        <div class="time-week__week-content">
          <div
              v-for="interval in intervals"
              :key="interval?.id"
              class="time-week__item"
          >
            <CheckboxUI
                v-model="model[item?.id][interval?.id]"
                :label="interval?.label ? interval.label : interval?.value"
                @change="handleOnChangeCheckbox"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CheckboxUI from './CheckboxUI'

export default {
  name: 'TimeWeekUI',
  emits: ['change'],
  components: {
    CheckboxUI
  },
  props: {
    attr: {
      type: Object,
      default: () => null
    },
    stringResult: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      intervals: null,
      daysWeek: null,
      model: null
    }
  },
  mounted () {
    const defaultIntervals = [
      {
        id: 'first',
        value: 'Утро (8:00 - 12:00)',
        label: '<span>Утро</span> (8:00 - 12:00)'
      },
      {
        id: 'second',
        value: 'День (12:00 - 18:00)',
        label: '<span>День</span> (12:00 - 18:00)'
      },
      {
        id: 'third',
        value: 'Вечер (18:00 - 22:00)',
        label: '<span>Вечер</span> (18:00 - 22:00)'
      }
    ]
    this.intervals = this.attr?.intervals ? this.attr?.intervals : defaultIntervals

    this.model = {}
    const defaultDaysWeek = [
      { id: 'mo', value: 'Пн' },
      { id: 'tu', value: 'Вт' },
      { id: 'we', value: 'Ср' },
      { id: 'th', value: 'Чт' },
      { id: 'fr', value: 'Пт' },
      { id: 'sa', value: 'Сб' },
      { id: 'su', value: 'Вс' }
    ]
    this.daysWeek = this.attr?.daysWeek ? this.attr?.daysWeek : defaultDaysWeek
    this.daysWeek.forEach(el => {
      this.model[el?.id] = {}
    })
  },
  methods: {
    handleOnChangeCheckbox () {
      const result = []

      this.daysWeek.forEach(week => {
        this.intervals.forEach((interval) => {
          if (this.model?.[week?.id]?.[interval?.id]) {
            const item = this.stringResult || this.attr?.stringResult
                ? `${week?.value}: ${interval?.value}`
                : {
                  week: week?.id,
                  interval: interval?.id
                }

            result.push(item)
          }
        })
      })

      this.$emit('change', result)
    }
  }
}
</script>
