<template>
  <button
    class="btn"
    :type="type"
    :disabled="disabled"
    :class="{'btn--outline': isOutline}"
    @click.prevent="click"
  >
     <span>{{ title }}</span>
  </button>
</template>

<script>
export default {
  name: 'ButtonUI',
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isOutline: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>
