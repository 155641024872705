<template>
  <div class="loader" :class="classes">
    <svg class="loader__element" viewBox="0 0 50 50">
      <circle
        class="loader__element-path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="3"
      ></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LoaderUI',

  props: {
    marginY: Boolean,
    marginXCenter: Boolean
  },

  computed: {
    classes () {
      const cls = []

      this.marginY && cls.push('spinner--margin-y')
      this.marginXCenter && cls.push('spinner--margin-x-center')

      return cls
    }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  display: block;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(109, 122, 149, 0.5);

  &__element {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80px;
    height: 80px;
    margin-left: -40px;
    margin-top: -40px;
    animation: rotate-04a0d67a 2s linear infinite;

    &-path {
      stroke: #2C5ECB;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
