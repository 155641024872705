<template>
  <div class="textarea-ui-wrap">
    <div class="textarea-ui">
      <label v-if="label">{{ label }}</label>
      <textarea
          :disabled="disabled"
          :placeholder="placeholder"
          :maxlength="maxLength"
          :value="modelValue"
          @input="change($event.target.value)"
      />
    </div>
    <div class="textarea-ui-note" v-if="isError">
      {{errorText}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextareaUI',
  props: {
    modelValue: null,
    isLabel: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String
    }
  },
  emits: ['update:modelValue', 'change'],
  methods: {
    change (value) {
      this.$emit('update:modelValue', value)
      this.$emit('change', value)
    }
  }
}
</script>
