<template>
  <div class="input-ui-wrap">
    <div class="input-ui">
      <label v-if="isLabel">{{ label }}</label>
      <input
        :type="type"
        :disabled="disabled"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :value="modelValue"
        v-maska="attr?.mask ? attr.mask : null"
        @input="change($event.target.value)"
      >
    </div>
    <div class="input-ui-note" v-if="isError">
      {{errorText}}
    </div>
  </div>
</template>

<script>
import { mask } from 'maska'

export default {
  name: 'InputUI',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    modelValue: null,
    isLabel: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String
    },
    attr: {
      type: Object,
      default: () => null
    }
  },
  emits: ['update:modelValue', 'change'],
  methods: {
    change (value) {
      this.$emit('update:modelValue', value)
      this.$emit('change', value)
    }
  }
}
</script>
